@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Barlow:wght@400;800&family=Bellefair&display=swap);
.VideoBackground_video_background_container__2OCHc {
  height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: -1;
}

.VideoBackground_video_background__F9uyQ {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.VideoBackground_video_background__F9uyQ:before {
  background-image: linear-gradient(to bottom right, #0100ec, #fb36f4);
  content: '';
  height: 100%;
  mix-blend-mode: hard-light;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.VideoBackground_video_background__F9uyQ iframe {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  mix-blend-mode: hard-light;
  object-fit: cover;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media screen and ( min-width : 900px ) {

  .VideoBackground_video_background__F9uyQ iframe {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media screen and ( min-width : 600px ) and ( min-height : 200px ) {

  .VideoBackground_video_background__F9uyQ iframe {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

*, *:before, *:after {
  box-sizing: border-box;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {

  --largura_celular: 90%;

  --largura_desktop: 60%;

  --text_shadow: -2px 0 2px #03051f;
}

body {
  color: white;
  font-family: 'Barlow Condensed' , sans-serif;
}

h1 {
  font-family: 'Bellefair' , serif;
  font-size: 55px;
  font-weight: 400;
  letter-spacing: 10px;
  text-align: center;
  text-shadow: -2px 0 2px #03051f;
  text-shadow: var(--text_shadow);
  text-transform: uppercase;
}

h2 {
  padding-bottom: 20px;
  text-align: left;
  text-transform: uppercase;
}


header, .conteiner_planeta_title, .conteiner_planeta, .conteiner_missions_title, .conteiner_missions {
  margin: auto;
  width: 90%;
  width: var(--largura_celular);
}


header {
  display: flex;
  padding: 50px 0;
}

.conteiner_planeta {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-template-rows: auto;
}

.planet-card {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.planet-card:before {
  background-color: #03051f;
  border-radius: 15px 0;
  bottom: 0;
  content: '';
  height: calc(100% - 40px);
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.planet-card h3 {
  border-top: 1px white dashed;
  display: block;
  font-size: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;
  text-shadow: -2px 0 2px #03051f;
  text-shadow: var(--text_shadow);
  text-transform: uppercase;
  width: 70%;
  z-index: 2;
}

.conteiner_planet_image {
  height: 80px;
  position: relative;
  width: 80px;
}

.conteiner_planet_image:before {
  border-radius: 100%;
  box-shadow: 14px 16px 20px #03051f;
  content: '';
  height: 40%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 40%;
  z-index: -1;
}

img {
  -webkit-filter: drop-shadow(-6px 4px 7px #03051f);
          filter: drop-shadow(-6px 4px 7px #03051f);
  height: 100%;
  object-fit: contain;
  width: 100%;
}

.conteiner_missions_title {
  padding-top: 80px;
}

.conteiner_missions {
  display: flex;
  flex-direction: column;
  grid-gap: 40px;
  gap: 40px;
  padding-bottom: 40px;
  position: relative;
}


.conteiner_mission {
  -webkit-backdrop-filter: blur(21px) saturate(180%);
          backdrop-filter: blur(21px) saturate(180%);
  background: #03051fa1;
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 12px;
  padding: 20px;
}

h3[ data-testid = 'mission-name' ] {
  border-bottom: 1px solid white;
  font-family: 'Barlow' , sans-serif;
  margin-bottom: 10px;
  padding-bottom: 15px;
  text-transform: uppercase;
}

.mission_data {
  display: grid;
  font-family: 'Barlow' , sans-serif;
  grid-template-columns: 1fr 2fr;
  line-height: 1.5;
}

.mission_data[ data-testid = 'mission-year' ]:before {
  content: 'Ano';
  font-weight: 800;
}

.mission_data[ data-testid = 'mission-country' ]:before {
  content: 'País';
  font-weight: 800;
}

.mission_data[ data-testid = 'mission-destination' ]:before {
  content: 'Destino';
  font-weight: 800;
}


@media screen and ( min-width : 600px ) {

  header, .conteiner_planeta_title, .conteiner_planeta, .conteiner_missions_title, .conteiner_missions {
    margin: auto;
    width: 90%;
    width: var(--largura_celular);
  }

  .planet-card {
    min-width: 100px;
  }

  .conteiner_missions {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}

@media screen and ( min-width : 800px ) {

  header, .conteiner_planeta_title, .conteiner_planeta, .conteiner_missions_title, .conteiner_missions {
    width: 60%;
    width: var(--largura_desktop);
  }

}


@media screen and ( min-width : 1000px ) {

  h1 {
    font-size: 5vw;
  }

  .conteiner_planeta {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .conteiner_missions {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }

}

